<template>
	<!-- 安祥禪曲 -->

	<!-- 顶部表格 -->
	<div class="zenMusic">
		<h3 class="ta-l mt10 mb10">{{ list1.cate_name }}</h3>
		<div class="firstTable">
			<div class="topTable" v-for="(item, index) in length1" :key="index" style="float:left;width:25%;"
				:class="{ clearFloat: index % 4 == 0 }">
				<table style="width:100%" cellspacing="0" cellpadding="0">
					<tr v-if="index <= 3">
						<th style="width: 60%;">{{ $t("songNane") }}</th>
						<th>{{ $t("songStyle") }}</th>
					</tr>
					<tr v-for="(item1, index1) in list1.lists[index].data" :key="index1">
						<td style="width: 60%;" v-if="index1 == 0" :rowspan="list1.lists[index].data.length || 1"
							class="leftTitle">
							<div v-if="list1.lists[index]">{{ list1.lists[index].name }}</div>
						</td>
						<td class="contentTitle">
							<div @click="linkToSongDetail(item1)" v-if="item1">
								{{ item1.class }}
							</div>
						</td>
					</tr>
				</table>
			</div>
		</div>
		<div class="tableBottomLine"></div>

		<!-- 下边的表格 -->
		<div v-for="(item, index) in list2" :key="index">
			<h3 class="ta-l mt10 mb10">{{ item.cate_name }}</h3>
			<table border="1" cellspacing="0" class="otherTable">
				<tr v-for="(item1, index1) in getLength(item.lists)" :key="index1">
					<td class="musicW">
						<div @click="linkToSongDetail(item.lists[4 * index1].data[0])" v-if="item.lists[4 * index1]">
							{{ item.lists[4 * index1].data[0].class }}
						</div>
					</td>
					<td class="musicW" @click="linkToDetail(list[index1])">
						<div @click="linkToSongDetail(item.lists[4 * index1 + 1].data[0])"
							v-if="item.lists[4 * index1 + 1]">
							{{ item.lists[4 * index1 + 1].data[0].class }}
						</div>
					</td>
					<td class="musicW">
						<div @click="linkToSongDetail(item.lists[4 * index1 + 2].data[0])"
							v-if="item.lists[4 * index1 + 2]">
							{{ item.lists[4 * index1 + 2].data[0].class }}
						</div>
					</td>
					<td class="musicW">
						<div @click="linkToSongDetail(item.lists[4 * index1 + 3].data[0])"
							v-if="item.lists[4 * index1 + 3]">
							{{ item.lists[4 * index1 + 3].data[0].class }}
						</div>
					</td>
				</tr>
			</table>

			<div class="tableBottomLine"></div>
		</div>

		<!-- <div class="note itemTitle">
			<div class="noteTitle">{{ $t("noteInfo").title1 }}</div> -->
			<!-- <div class="mt1"><a href="http://chanxue.pc.langqiyun.cn/files/songs.doc" target="_blank">{{
                $t("noteInfo").info1 }}</a></div>
			<div class="mt1"><a href="http://chanxue.pc.langqiyun.cn/files/mp3.rar" target="_blank">{{
                $t("noteInfo").info2 }}</a></div>
			<div class="mt1"><a href="http://chanxue.pc.langqiyun.cn/files/mid.rar" target="_blank">{{
                $t("noteInfo").info3 }}</a></div>
			<div class="mt1">
				<span class="noteTitle">{{ $t("noteInfo").title2 }}</span>
				<a href="http://chanxue.pc.langqiyun.cn/files/anxiang.zip" target="_blank">{{ $t("noteInfo").info4
                    }}</a>
			</div> -->
			 <!--- one列表  后期修改的-->
			<!-- <div class="mt1" v-for="(item,index) in one" :key="index">  -->
				<!-- <a :href="item.file" target="_blank" v-if="item.file">{{item.name}}</a>
				<a :href="item.link" target="_blank" v-else>{{item.name}}</a>
			</div>
			<div class="mt1" style="display:flex;width:100%;">
				<span class="noteTitle">{{ $t("noteInfo").title2 }}</span>
				<div style="display:flex; width:100%;">
					<div style="margin-left:5px;" v-for="(item,index) in tow" :key="index">
					<a v-if="item.file" :href="item.file" target="_blank">{{item.name}}</a>
					<a v-else :href="item.link" target="_blank">{{item.name}}</a>
				</div>
				</div>
			</div>
		</div> -->
	</div>
</template>

<script>
	import {
		getCateList,
		getAudioList,
		herfList
	} from "../../api/apis";

	export default {
		name: "",
		components: {},

		data() {
			return {
				length1: 5,
				length2: 0,
				list1: [
					// {
					//     title: "dsd",
					//     lists: [{ name: 11 }, { name: 22 }]
					// },
					// {
					//     title: "dsd",
					//     lists: [{ name: 11 }, { name: 22 }, { name: 33 }]
					// },
					// {
					//     title: "dsd",
					//     lists: [{ name: 11 }, { name: 22 }, { name: 33 }]
					// },
					// {
					//     title: "dsd",
					//     lists: [{ name: 11 }, { name: 22 }, { name: 33 }]
					// },
					// {
					//     title: "dsd",
					//     lists: [{ name: 11 }, { name: 22 }, { name: 33 }, {}]
					// }
				],
				list2: [{
					name: 1
				}, {
					name: 2
				}, {
					name: 3
				}, {
					name: 4
				}, {
					name: 5
				}],
				one:[],
				tow:[]
			};
		},
		methods: {
			//歌曲详情
			linkToSongDetail(item) {
				window.open(this.$BaseURL + "/musicDetail?name=frenchp&id=" + item.id);
				// this.$router.push({
				//     path: "/musicDetail",
				//     query: { name: "frenchp", id: item.id }
				// });
			},
			// 获取子列表lists的长度,向上取整
			getLength(arr) {
				arr = arr || [{}];
				let length = arr.length;
				this.length2 = Math.ceil(length / 4);
				return Math.ceil(length / 4);
			},
			initData(arr) {
				//初始化list1中的lists
				let length = Math.ceil(arr.length / 4);

				let diffCount = length * 4 - arr.length;
				//补全数据(数据为4的倍数)
				if (diffCount > 0) {
					for (let i = 0; i < diffCount; i++) {
						arr[arr.length] = {
							name: "",
							data: []
						};
					}
				}

				//初始化lists下每项的data的长度，4个一组，每组长度统一
				for (let i = 0; i < length; i++) {
					let index = 4 * i;
					let maxLength = arr[index].data.length;
					if (arr[index + 1].data.length > maxLength) {
						maxLength = arr[index + 1].data.length;
					}
					if (arr[index + 2].data.length > maxLength) {
						maxLength = arr[index + 2].data.length;
					}
					if (arr[index + 3].data.length > maxLength) {
						maxLength = arr[index + 3].data.length;
					}
					//比较完后，找到最大的lenngth，统一改变数组的length
					arr[index].data.length = arr[index + 1].data.length = arr[
						index + 2
					].data.length = arr[index + 3].data.length = maxLength;
				}
				this.length1 = arr.length;
				return arr;
			},
		},
		created() {
			// this.length2 = this.getLength(this.list2 || [{}]);
			// this.list1 = this.initData(this.list1);
			// this.length1 = this.list1.length;

			getCateList().then((res) => {
				let id = res[1][6].id;
				getAudioList(id).then((res) => {
					// this.list1 = res.slice(0,1);
					if (Array.isArray(res[0].lists)) {
						this.list1 = res[0];
						this.list1.lists = this.initData(res[0].lists);
					} else {
						this.list1.lists = [{}];
					}

					this.length2 = this.getLength(this.list2 || [{}]);
					this.list2 = res.slice(1);
				});
			});
			herfList().then(res=>{
				console.log(res)
				this.one = res.one
				this.tow = res.two
			})
		},
	};
</script>

<style lang="stylus" scoped>
	.ta-l {
		text-align: left;
	}

	.pl5 {
		padding-left: 5px;
	}

	.zenMusic {
		width: 750px;
		margin: 20px 10px;
		font-size: 15px;

		.title {
			font-size: 15px;
			font-weight: 600;
		}
	}

	.clearFloat {
		clear: both !important;
	}

	table,
	td,
	tr,
	th {
		border: 1px solid #ACACAC;
		border-bottom: 0px solid #ACACAC;
	}

	.tableBottomLine {
		width: 100%;
		height: 1px;
		background-color: #ACACAC;
	}

	table {
		width: 100%;
		font-size: 15px;

		th {
			line-height: 36px;
			font-weight: 600;
			font-size: 15px;
			// padding-left: 14px;
			box-sizing: border-box;
		}

		td {
			line-height: 30px;
			vertical-align: middle;
			box-sizing: border-box;
		}

		tr {
			height: 31px;
			box-sizing: border-box;
		}

		.fileStyle:hover {
			color: #1482d9;
			cursor: pointer;
		}
	}

	.musicW {
		width: 25%;
	}

	.firstTable {
		float: left;
		width: 100%;
		border-bottom: 1px solid #ACACAC;
		border-right: 1px solid #ACACAC;
		margin-bottom: 15px;
	}

	.topTable {
		box-sizing: border-box;

		table,
		tr,
		td,
		th {
			border-right: 0px solid #ccc !important;
			box-sizing: border-box;
		}
	}

	.contentTitle:hover {
		cursor: pointer;
		color: #1482d9;
	}

	.otherTable {
		td:hover {
			cursor: pointer;
			color: #1482d9;
		}
	}

	.note {
		text-align: left;
		line-height: 30px;
		margin-top: 20px;

		.noteTitle {
			font-weight: 600;
			width:200px;
		}

		a {
			text-decoration none;
			color black;
		}

		a:hover {
			color: #2b80c3;
		}
	}
</style>